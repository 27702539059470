.ContainerAbout{
    background-image: url('../images/RoofService.jpeg');
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;  
    min-height: 600px; 
    width: 100vw;
    height: 100%;
    object-position: 50% 20%;
    object-fit: cover;
}

.WrapperAbout{
    display:flex;
    flex-direction:column;
    align-items: flex-end;
    justify-content: center; 
    min-height: 600px; 
    width: 100%;
    height: 100%;
}

.HeaderSectionAbout{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:20px;
    /* margin-top:40px; */
}

.RightSideAbout{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 200px; 
    color:white;
    margin: 0;
    
}
h10{
    font-size: clamp(2rem, 3vw, 4rem); 
    font-family: 'Anton', sans-serif;
    font-weight: 500;
    font-style: normal;
    text-align: left;
}
.LeftSideAbout{
    background: linear-gradient(45deg, #BE7429, #FACD89);
    width: 50vw;
    height: 100vh;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}
.LeftAbout{
    /* display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center; */
    width: 80%;
    /* background-color: aliceblue; */
}
.aboutbio{
    margin-top: 15px; 
    font-size: 14pt;
    /* font-size: clamp(1.5rem, 2vw, 4rem); */
    margin-top: -10px;
    font-family: 'Mallanna';
}

.AboutDetailAbout{
    width:65%;
    text-align: center;   
}


@media only screen and (max-width: 768px) {
    .HeaderSectionAbout {
        grid-template-columns: auto;
        grid-gap:0px;
        margin-top:0px;
    }

  }

    .LeftSideAbout{
        display:flex;
        flex-direction:column;
        /* align-items: center; */
        justify-content: center;
        cursor: pointer;
        color:white;
        
    }