.HeaderSection{
    background-image: url('./images/houses.jpg');
    background-attachment: fixed;
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;  
    /* min-height: 20vh; 
    height: 100%; */
    background-position: 10% 10%;
    object-fit: cover;
}



.HomeElement{
  background-color: #ededed;    
  height:100vh;
}

.HeaderSection{
  display: grid;
  grid-template-columns: 50% 40%; /* Use relative units */
}


  .companyName{
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: clamp(3rem, 2.5vw, 2rem); 
    text-align: center;

  }
  .desc{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: clamp(16px, 2vw, 8px); 

  }
  .details{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh; /* Default: 50% of the viewport height */
    max-height: 600px; /* Prevent it from growing too tall */
    min-height: 200px; /* Ensure it doesn’t shrink too much */
    background-color: #ededed;    

  }
  
  .descRight{
    text-align: right;
  }
  .descLeft{
    text-align: left;
  }
  .info{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
    width:100%;
  }
  .something{
    display: grid;
    grid-template-columns: 1.5fr 1.5fr;
    gap: 20px;
    width: 90%; /* Default: 80% of the parent container */
    max-width: 1200px; /* Ensure it doesn't get too wide */
    min-width: 300px; /* Set a minimum width for usability */
    margin: 0 auto; /* Center it horizontally */  
  }
  .margin{
    margin-top: 7px;
  }

  
@media only screen and (max-width: 768px) {
  .Container{
    padding-bottom:5rem
  }
  .HeaderSection{
    display: grid;
    grid-template-columns: 30rem;
  }
  .Section{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
  .SectionRight{
    background-image: url('./images/houses.jpg');
    background-attachment: fixed;
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;  
    min-height: 20vh; 
    height: 100%;
    background-position: 10% 10%;
    object-fit: cover;
  }

  .something{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 80%; /* Default: 80% of the parent container */
    max-width: 1200px; /* Ensure it doesn't get too wide */
    min-width: 300px; /* Set a minimum width for usability */
    margin: 0 auto; /* Center it horizontally */  
  }
  .descRight{
    text-align: left;
  }
}